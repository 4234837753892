import { v4 as uuidv4 } from 'uuid';
import React from 'react';
import { Area, AreaChart, ResponsiveContainer, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import { ThemeProvider, Tooltip as MuiTooltip } from '@mui/material';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import accounts from './accounts.json';
import { theme } from '../utils/theme.jsx';

function valToString(val) {
  return val.toLocaleString('default', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}

function getAccountDescription(account) {
  while (account) {
    const accountDescription = accounts[account.toString()];
    if (accountDescription) {
      return accountDescription;
    }
    account = Math.floor(account / 10);
  }
  return '';
}

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: 'rgba(255, 255, 255, 0.95)',
          padding: '10px',
          border: '1px solid #ccc',
          borderRadius: '4px',
          boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
        }}
      >
        <table>
          <tbody>
            <tr>
              <td style={{ color: '#666', paddingRight: '10px' }}>Date:</td>
              <td style={{ fontWeight: 'bold' }}>{label}</td>
            </tr>
            <tr>
              <td style={{ color: '#666', paddingRight: '10px' }}>Month Total:</td>
              <td style={{ fontWeight: 'bold' }}>{valToString(payload[0].payload.monthTotal)}</td>
            </tr>
            <tr>
              <td style={{ color: '#666', paddingRight: '10px' }}>Total:</td>
              <td style={{ fontWeight: 'bold' }}>{valToString(payload[0].payload.total)}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
  return null;
};

const gradientOffset = (data) => {
  const dataMax = Math.max(...data.map((i) => i.total));
  const dataMin = Math.min(...data.map((i) => i.total));

  if (dataMax <= 0) {
    return 0;
  }
  if (dataMin >= 0) {
    return 1;
  }

  return dataMax / (dataMax - dataMin);
};

export function TransactionsGraph(transactions_, include7219) {
  const data = [];
  if (transactions_.length) {
    const transactions = [];
    for (const t of transactions_) {
      if (t.account === '7219' && !include7219) {
        continue;
      }

      const d_ = new Date(t.date);
      const dc_ = new Date(t.datecreated);
      t.date = d_;
      t.datecreated = dc_;
      t.id = uuidv4();

      transactions.push(t);
    }

    transactions.sort(function (a, b) {
      return a.date - b.date;
    });

    let monthTransactions = [];
    let monthTotal = 0.0;
    let curDate = new Date(transactions[0].date.getFullYear(), transactions[0].date.getMonth());

    let total = 0.0;
    for (let i = 0; i < transactions.length; i++) {
      const t = transactions[i];
      const date = new Date(t.date.getFullYear(), t.date.getMonth());

      if (date > curDate) {
        data.push({
          id: uuidv4(),
          date: curDate,
          dateShort: `${curDate.getFullYear()}-${curDate.getMonth() + 1}`,
          monthTotal,
          total,
        });
        monthTotal = 0.0;
        monthTransactions = [];
        curDate = date;
      }

      total += t.amount;
      monthTotal += t.amount;
      t.total = total;
      monthTransactions.push(t);
    }
    data.push({ date: curDate, dateShort: `${curDate.getFullYear()}-${curDate.getMonth() + 1}`, monthTotal, total });
  }

  const off = gradientOffset(data);

  return (
    <div style={{ width: '100%', height: '400px' }}>
      <ResponsiveContainer>
        <AreaChart
          data={data}
          margin={{
            top: 10,
            right: 25,
            left: 25,
            bottom: 10,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="dateShort" />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          <defs>
            <linearGradient id="splitColor" x1="0" y1="0" x2="0" y2="1">
              <stop offset={off} stopColor="green" stopOpacity={0.3} />
              <stop offset={off} stopColor="red" stopOpacity={0.3} />
            </linearGradient>
          </defs>
          <Area type="monotone" dataKey="total" stroke="#000" fill="url(#splitColor)" />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}

function Row(props) {
  const { row, isMobile } = props;
  const [open, setOpen] = React.useState(false);

  row.transactions.sort(function (a, b) {
    return b.date - a.date;
  });

  return (
    <>
      <ThemeProvider theme={theme}>
        <TableRow
          sx={{
            '& > *': { borderBottom: 'unset' },
            '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' },
          }}
          key={row.id}
        >
          <TableCell padding="none">
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          {!isMobile && (
            <TableCell component="th" scope="row" align="right" className="table-new">
              <MuiTooltip title="New transaction(s) since last login">
                <p>{row.anyNew ? '❉' : ''}</p>
              </MuiTooltip>
            </TableCell>
          )}
          <TableCell>{`${row.date.getFullYear()} ${row.date.toLocaleString('default', { month: 'short' })}`}</TableCell>
          <TableCell align="right">{valToString(row.monthTotal)}</TableCell>
          {!isMobile && <TableCell align="right">{valToString(row.total)}</TableCell>}
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box
                sx={{
                  margin: 1,
                  backgroundColor: 'rgba(0, 0, 0, 0.02)',
                  padding: '16px',
                  borderRadius: '4px',
                }}
              >
                <h3
                  style={{
                    margin: '0 0 16px 0',
                    color: theme.palette.primary.main,
                  }}
                >
                  Transactions for {row.transactions[0].date.toLocaleString('default', { month: 'long' })}{' '}
                  {row.transactions[0].date.getFullYear()}
                </h3>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell className="table-header" />
                      <TableCell className="table-header tooltip-highlight">
                        <MuiTooltip theme={theme} title="Date when the transaction was made">
                          <p>Date</p>
                        </MuiTooltip>
                      </TableCell>
                      <TableCell className="table-header tooltip-highlight">
                        <MuiTooltip
                          theme={theme}
                          title="Date when the transaction was added into the accounting system"
                        >
                          <p>Booked</p>
                        </MuiTooltip>
                      </TableCell>
                      <TableCell className="table-header" align="left">
                        Account No.
                      </TableCell>
                      <TableCell className="table-header" align="left">
                        Account Description
                      </TableCell>
                      <TableCell className="table-header" align="right">
                        Amount
                      </TableCell>
                      <TableCell className="table-header">Description</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.transactions.map((transactionsRow) => (
                      <TableRow key={transactionsRow.id}>
                        <TableCell component="th" scope="row" align="right" className="table-new">
                          <MuiTooltip theme={theme} title="New transaction since last login">
                            <p>{transactionsRow.isNew ? '❉' : ''}</p>
                          </MuiTooltip>
                        </TableCell>
                        <TableCell>{`${transactionsRow.date.getFullYear()}-${transactionsRow.date.getMonth() + 1}-${transactionsRow.date.getDate()}`}</TableCell>
                        <TableCell>{`${transactionsRow.datecreated.getFullYear()}-${transactionsRow.datecreated.getMonth() + 1}-${transactionsRow.datecreated.getDate()}`}</TableCell>
                        <TableCell align="left">{transactionsRow.account}</TableCell>
                        <TableCell align="left">{getAccountDescription(transactionsRow.account)}</TableCell>
                        <TableCell align="right">{valToString(transactionsRow.amount)}</TableCell>
                        <TableCell>{transactionsRow.description}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </ThemeProvider>
    </>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.string.isRequired,
    date: PropTypes.instanceOf(Date).isRequired,
    monthTotal: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    transactions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        date: PropTypes.instanceOf(Date).isRequired,
        datecreated: PropTypes.instanceOf(Date).isRequired,
        amount: PropTypes.number.isRequired,
        description: PropTypes.string.isRequired,
        account: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
};

export function TransactionsTable(transactions_, lastLogin, include7219) {
  const data = [];
  if (transactions_.length) {
    const transactions = [];
    for (const t of transactions_) {
      if (t.account === '7219' && !include7219) {
        continue;
      }

      const d_ = new Date(t.date);
      const dc_ = new Date(t.datecreated);
      t.date = d_;
      t.datecreated = dc_;
      t.id = uuidv4();

      transactions.push(t);
    }

    transactions.sort(function (a, b) {
      return a.date - b.date;
    });

    let monthTransactions = [];
    let monthTotal = 0.0;
    let curDate = new Date(transactions[0].date.getFullYear(), transactions[0].date.getMonth());
    let anyNew = false;

    let total = 0.0;
    for (let i = 0; i < transactions.length; i++) {
      const t = transactions[i];
      const date = new Date(t.date.getFullYear(), t.date.getMonth());

      if (date > curDate) {
        data.push({
          id: uuidv4(),
          anyNew,
          date: curDate,
          dateShort: `${curDate.getFullYear()}-${curDate.getMonth() + 1}`,
          monthTotal,
          total,
          transactions: monthTransactions,
        });
        monthTotal = 0.0;
        monthTransactions = [];
        curDate = date;
        anyNew = false;
      }

      total += t.amount;
      monthTotal += t.amount;
      t.total = total;

      t.isNew = lastLogin < t.datecreated;
      if (t.isNew) {
        anyNew = true;
      }
      monthTransactions.push(t);
    }
    data.push({
      id: uuidv4(),
      anyNew,
      date: curDate,
      dateShort: `${curDate.getFullYear()}-${curDate.getMonth() + 1}`,
      monthTotal,
      total,
      transactions: monthTransactions,
    });
  }

  data.sort(function (a, b) {
    return b.date - a.date;
  });

  return (
    <TableContainer component={Paper}>
      <ThemeProvider theme={theme}>
        <Table size="small" aria-label="transactions">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell />
              <TableCell align="left">Date</TableCell>
              <TableCell align="right">Month total</TableCell>
              <TableCell align="right">Balance</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => (
              <Row key={row.id} row={row} />
            ))}
          </TableBody>
        </Table>
      </ThemeProvider>
    </TableContainer>
  );
}
