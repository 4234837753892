import React, { useState, useMemo, useEffect } from 'react';
import { useLoaderData } from 'react-router-dom';
import {
  Container,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  Box,
  IconButton,
  Chip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Badge,
  Snackbar,
  Alert,
  Drawer,
} from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import EnlargedImageModal from '../components/EnlargedImageModal.jsx';
import ShoppingCart from '../components/ShoppingCart.jsx';
import { green } from '@mui/material/colors';
import { backend, IntegrationColor } from '../components/user.jsx';

const ArticleCard = ({ article, addToCart }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [selectedSize, setSelectedSize] = useState(() => {
    if (article.attributes && article.attributes.length > 0) {
      const firstAvailableAttribute = article.attributes.find((attr) => attr.quantity > 0);
      return firstAvailableAttribute ? firstAvailableAttribute.attributeId : '';
    }
    return '';
  });
  const [showAllSizes, setShowAllSizes] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const nextImage = () => {
    if (article.images && article.images.length > 0) {
      setCurrentImageIndex((prevIndex) => (prevIndex === article.images.length - 1 ? 0 : prevIndex + 1));
    }
  };

  const prevImage = () => {
    if (article.images && article.images.length > 0) {
      setCurrentImageIndex((prevIndex) => (prevIndex === 0 ? article.images.length - 1 : prevIndex - 1));
    }
  };

  const handleImageClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const visibleAttributes = showAllSizes ? article.attributes : article.attributes?.slice(0, 4);

  return (
    <>
      <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ position: 'relative' }}>
          {/* Collection chip */}
          {article.collection && (
            <Chip
              label={article.collection}
              sx={{
                position: 'absolute',
                top: 8,
                left: 8,
                zIndex: 1,
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
              }}
            />
          )}

          {/* Integration chips */}
          <Box
            sx={{
              position: 'absolute',
              top: 8,
              right: 8,
              zIndex: 1,
              display: 'flex',
              flexDirection: 'column',
              gap: 0.5,
            }}
          >
            {article.integrations && article.integrations.length > 0 ? (
              article.integrations.map((integration, index) => (
                <Chip
                  key={index}
                  label={integration}
                  sx={{
                    backgroundColor: IntegrationColor(integration),
                    color: 'white',
                  }}
                />
              ))
            ) : (
              <Chip
                label="Group"
                sx={{
                  backgroundColor: IntegrationColor('group'),
                  color: 'white',
                }}
              />
            )}
          </Box>

          <CardMedia
            component="img"
            height="400"
            image={article.images && article.images.length > 0 ? article.images[currentImageIndex] : ''}
            alt={article.name}
            onClick={handleImageClick}
            sx={{
              cursor: 'pointer',
              objectFit: 'contain',
              bgcolor: 'white',
            }}
          />
          {article.images && article.images.length > 1 && (
            <>
              <IconButton
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: 8,
                  transform: 'translateY(-50%)',
                  bgcolor: 'rgba(255,255,255,0.7)',
                }}
                onClick={prevImage}
              >
                <ArrowBackIosNewIcon />
              </IconButton>
              <IconButton
                sx={{
                  position: 'absolute',
                  top: '50%',
                  right: 8,
                  transform: 'translateY(-50%)',
                  bgcolor: 'rgba(255,255,255,0.7)',
                }}
                onClick={nextImage}
              >
                <ArrowForwardIosIcon />
              </IconButton>
            </>
          )}
        </Box>
        <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
          <Typography gutterBottom variant="h5" component="div">
            {article.name}
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            paragraph
            sx={{ flexGrow: 1, overflow: 'auto', maxHeight: '200px', mb: 2 }}
          >
            {article.description}
          </Typography>
          <Box sx={{ mt: 'auto' }}>
            <Typography variant="h6" color="text.primary" gutterBottom>
              {article.price.toFixed(2)} Kr
            </Typography>
            {article.attributes && (
              <Box sx={{ mb: 2 }}>
                <Typography variant="subtitle1" gutterBottom>
                  Options:
                </Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                  {visibleAttributes.map(({ name, attributeId, quantity }) => (
                    <Button
                      key={attributeId}
                      variant={selectedSize === attributeId ? 'contained' : 'outlined'}
                      size="small"
                      onClick={() => setSelectedSize(attributeId)}
                      disabled={quantity === 0}
                      sx={{
                        bgcolor: selectedSize === attributeId ? green[500] : 'transparent',
                        color: selectedSize === attributeId ? 'white' : green[500],
                        borderColor: green[500],
                        '&:hover': {
                          bgcolor: selectedSize === attributeId ? green[600] : green[50],
                        },
                        '&:disabled': {
                          bgcolor: 'grey.300',
                          color: 'grey.500',
                          borderColor: 'grey.300',
                        },
                      }}
                    >
                      {name}
                    </Button>
                  ))}
                  {article.attributes.length > 4 && (
                    <Button
                      size="small"
                      onClick={() => setShowAllSizes(!showAllSizes)}
                      sx={{
                        color: green[500],
                        '&:hover': {
                          bgcolor: green[50],
                        },
                      }}
                    >
                      {showAllSizes ? 'Show Less' : `+${article.attributes.length - 4} More`}
                    </Button>
                  )}
                </Box>
              </Box>
            )}
            <Button
              variant="contained"
              startIcon={<AddShoppingCartIcon />}
              fullWidth
              disabled={article.attributes && !selectedSize}
              onClick={() => addToCart({ ...article, size: selectedSize })}
              sx={{
                bgcolor: green[500],
                '&:hover': {
                  bgcolor: green[700],
                },
                '&:disabled': {
                  bgcolor: green[200],
                },
              }}
            >
              Add to Cart
            </Button>
          </Box>
        </CardContent>
      </Card>
      <EnlargedImageModal
        open={isModalOpen}
        handleClose={handleCloseModal}
        imageSrc={article.images && article.images.length > 0 ? article.images[currentImageIndex] : ''}
        onNext={nextImage}
        onPrev={prevImage}
        hasMultipleImages={article.images && article.images.length > 1}
      />
    </>
  );
};

export function Shop() {
  const articles = useLoaderData() || [];
  const [selectedCollection, setSelectedCollection] = useState('All');
  const [cart, setCart] = useState(() => {
    const savedCart = sessionStorage.getItem('cart');
    return savedCart ? JSON.parse(savedCart) : [];
  });
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const collections = useMemo(() => {
    if (!articles) return ['All'];
    const allCollections = articles.map((article) => article.collection).filter(Boolean);
    return ['All', ...new Set(allCollections)];
  }, [articles]);

  const filteredArticles = useMemo(() => {
    if (!articles) return [];
    if (selectedCollection === 'All') {
      return articles;
    }
    return articles.filter((article) => article.collection === selectedCollection);
  }, [selectedCollection, articles]);

  const handleCollectionChange = (event) => {
    setSelectedCollection(event.target.value);
  };

  useEffect(() => {
    sessionStorage.setItem('cart', JSON.stringify(cart));
  }, [cart]);

  const addToCart = (item) => {
    const selectedAttributeData = item.attributes.find((a) => a.attributeId === item.size);
    if (!selectedAttributeData || selectedAttributeData.quantity === 0) {
      setSnackbarMessage('This size is out of stock');
      setSnackbarOpen(true);
      return;
    }

    setCart((prevCart) => {
      const existingItemIndex = prevCart.findIndex(
        (cartItem) => cartItem.id === item.id && cartItem.attributeId === item.size
      );
      if (existingItemIndex !== -1) {
        // If item with the same size already exists, increase quantity if stock allows
        const newCart = [...prevCart];
        if (newCart[existingItemIndex].quantity < selectedAttributeData.quantity) {
          newCart[existingItemIndex].quantity += 1;
          sessionStorage.setItem('cart', JSON.stringify(newCart));
          return newCart;
        } else {
          setSnackbarMessage('No more stock available for this size');
          setSnackbarOpen(true);
          return prevCart;
        }
      } else {
        // If item doesn't exist or has a different size, add it as a new entry
        const newCart = [
          ...prevCart,
          {
            ...item,
            attributeId: item.size,
            sizeName: selectedAttributeData.name,
            quantity: 1,
          },
        ];
        sessionStorage.setItem('cart', JSON.stringify(newCart));
        return newCart;
      }
    });
  };

  const removeFromCart = (id, attributeId) => {
    setCart(cart.filter((item) => !(item.id === id && item.attributeId === attributeId)));
  };

  const updateQuantity = (id, attributeId, newQuantity) => {
    if (newQuantity < 1) return;
    setCart(
      cart.map((item) =>
        item.id === id && item.attributeId === attributeId ? { ...item, quantity: newQuantity } : item
      )
    );
  };

  const clearCart = () => {
    setCart([]);
  };

  const toggleCart = () => {
    setIsCartOpen(!isCartOpen);
  };

  const placeOrder = async () => {
    try {
      const orderItems = cart.map((item) => ({
        articleId: item.id,
        attributeId: item.attributeId,
        quantity: item.quantity,
      }));

      const response = await backend.post('/shop/orders', orderItems);

      if (response.status !== 201) {
        throw new Error('Failed to place order');
      }

      setCart([]);
      sessionStorage.removeItem('cart');
      setSnackbarMessage('Order placed successfully!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      setIsCartOpen(false);

      // Automatically close the success message after 3 seconds
      setTimeout(() => {
        setSnackbarOpen(false);
      }, 3000);
    } catch (error) {
      console.error('Error placing order:', error);
      setSnackbarMessage('Failed to place order. Please try again.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const totalQuantity = useMemo(() => {
    return cart.reduce((total, item) => total + item.quantity, 0);
  }, [cart]);

  return (
    <Container>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          mb: 6,
          gap: 2,
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <FormControl sx={{ minWidth: '200px' }}>
            <InputLabel id="collection-select-label">Filter by Collection</InputLabel>
            <Select
              labelId="collection-select-label"
              id="collection-select"
              value={selectedCollection}
              label="Filter by Collection"
              onChange={handleCollectionChange}
            >
              {collections.map((collection) => (
                <MenuItem key={collection} value={collection}>
                  {collection}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <IconButton
            onClick={toggleCart}
            sx={{
              color: green[500],
              '&:hover': {
                color: green[700],
              },
            }}
          >
            <Badge
              badgeContent={totalQuantity}
              color="secondary"
              sx={{
                '& .MuiBadge-badge': {
                  fontSize: '0.8rem',
                  height: '22px',
                  minWidth: '22px',
                },
              }}
            >
              <ShoppingCartIcon sx={{ fontSize: 40 }} />
            </Badge>
          </IconButton>
        </Box>
      </Box>

      {/* Main content */}
      {articles && articles.length > 0 ? (
        <Grid container spacing={4}>
          {filteredArticles.map((article) => (
            <Grid item key={article.id} xs={12} sm={6} md={4}>
              <ArticleCard article={article} addToCart={addToCart} />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Alert severity="info" sx={{ mt: 4 }}>
          No articles found.
        </Alert>
      )}

      {/* Shopping Cart Drawer */}
      <Drawer
        anchor="right"
        open={isCartOpen}
        onClose={toggleCart}
        sx={{
          '& .MuiDrawer-paper': {
            width: '100%',
            maxWidth: { xs: '100%', sm: 400 },
            boxSizing: 'border-box',
            p: 2,
          },
        }}
      >
        <ShoppingCart
          cart={cart}
          removeFromCart={removeFromCart}
          updateQuantity={updateQuantity}
          clearCart={clearCart}
          closeCart={toggleCart}
          placeOrder={placeOrder}
        />
      </Drawer>

      {/* Updated Snackbar for notifications */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{
            width: '100%',
            bgcolor: snackbarSeverity === 'error' ? '#FFEBEE' : '#E8F5E9', // Lighter green for success
            color: snackbarSeverity === 'error' ? '#D32F2F' : '#2E7D32', // Darker green text for contrast
          }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export async function shopLoader() {
  try {
    const response = await backend.get('/shop/shop');

    if (response.status !== 200) {
      throw new Response('Failed to fetch shop articles', {
        status: response.status,
        statusText: response.statusText,
      });
    }

    return response.data;
  } catch (err) {
    if (err?.status) {
      throw new Response('', { status: err.status });
    }
    throw err;
  }
}
