import React from 'react';
import { useLoaderData } from 'react-router-dom';
import { TransactionsGraph, TransactionsTable } from '../components/Transactions.jsx';
import { LastLogin, backend } from '../components/user.jsx';

export function BalanceAccount() {
  const transactions = useLoaderData();
  const lastLogin = LastLogin();

  return (
    <div style={{ width: '100%', padding: '20px' }}>
      {TransactionsGraph(transactions, false)}
      {TransactionsTable(transactions, lastLogin, false)}
    </div>
  );
}

export const balanceAccountLoader = async () => {
  try {
    const response = await backend.get('/my/account');
    if (response.status !== 200) {
      throw new Response('Failed to fetch balance account', {
        status: response.status,
        statusText: response.statusText,
      });
    }
    return response.data;
  } catch (err) {
    if (err?.status) {
      throw new Response('', { status: err.status });
    }
    throw err;
  }
};
